<template>
    <div class="action-share-done">
        <div class="title" v-html="$translate('ACTION_SHARE_DONE_TITLE')" />
        <div class="body">
            <div v-html="$translate('ACTION_SHARE_DONE_BODY')" />
            <div class="footer m-t-16" v-html="$translate('ACTION_SHARE_DONE_FOOTER')" />
        </div>
        <div class="p-l-12 p-r-12 p-b-12">
            <button disabled class="btn btn-primary btn-block" v-html="$translate('ACTION_SHARE_REQUEST_ACCEPTED')" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionShareDone',
    props: ['message'],
}
</script>
